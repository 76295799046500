import { Component, OnInit, ViewChild, HostBinding, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { ListCommentsComponent } from './list-comments.component'
import { DeviceInfoService } from '@core/services/device-info.service';

@Component({
  selector: '[xa-comment-icon]',
  templateUrl: './comment-icon.component.html',
  styleUrls: ['./comment-icon.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, MaterialModule, TranslateModule, CommonModule]
})
export class CommentIconComponent {
  @HostBinding('className') componentClass: string;
  @ViewChild("comment") comment: ElementRef;
  @Input() objectId: number;
  @Output() expansionEvent = new EventEmitter<number>();

  constructor(
    private listCommentsComponent: ListCommentsComponent,
    private renderer: Renderer2,
    public deviceInfo: DeviceInfoService
  ) {
    this.componentClass = 'xa-comment-icon';
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.comment.nativeElement, 'click', (event) => {
      this.expansionEvent.emit(this.objectId);
    })
  }
}
