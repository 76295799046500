import { Component, Injectable, OnInit, AfterViewInit, ViewChild, ElementRef,  Renderer2, EventEmitter, HostListener, Output, HostBinding } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { DateTime } from 'luxon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { filter, mergeMap, map } from 'rxjs/operators';
import { from } from 'rxjs';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FileSaverService } from 'ngx-filesaver';

import { Credential } from '@modules/account/models/credential.model';
import { AccountService } from '@modules/account/services/account.service';
import { Message } from '@modules/message/models/message.model';
import { MessageService } from '@modules/message/services/message.service';
import { Profile } from '@modules/profile/models/profile.model';
import { ProfileService } from '@modules/profile/services/profile.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { environment } from '@environments/environment';
import { ReportComponent } from '@modules/report/components/report.component';
import { FileUploadService } from '@core/services/file-upload.service';

@Component({
  selector: '[xa-message-thread]',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss']
})
export class ThreadComponent implements OnInit, AfterViewInit {
  @HostBinding('className') componentClass: string;
  @ViewChild('messageListContainer') messageListContainer: ElementRef;
  @ViewChild("textMsg") textMsgRef: ElementRef;
  isMediaRecorderVisible: boolean = false;
  isSubmitted: boolean = false;
  action: string;
  form: any;
  active: boolean = true;
  activeFalseMsg: any;
  messages: any = null;
  credential: Credential;
  // message: Message | undefined;
  profile: any;
  threadId: any;
  participants: any = [];
  sendTo: any;
  isOpen: any;
  showEmojiPicker = false;
  isLoaded = false;
  scrollToBottom = false;
  hasChars = false;
  sendedAtDate: any;
  sendedAtDate1: any;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  attachments: any = [];
  files: any = [];
  @Output() newThreadItemEvent = new EventEmitter<any>();

  blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  constructor(
    private breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public deviceInfo: DeviceInfoService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private uploadService: FileUploadService,
    private http: HttpClient,
    private fileSaverService: FileSaverService
  ) {
    this.componentClass = 'xa-message-thread';
    this.form = this.formBuilder.group({
      textMsg: [''],
      audioMsg: [''],
      recipientId: [],
      senderId: [],
      sendedAt: [],
      threadId: [],
    });

    this.credential = this.accountService.credentialValue;
    this.form.controls.textMsg.setValue("");
  }

  close(): void {
    this.isOpen = false;
    this.router.navigate(['/messages/']);
  }

  ngOnInit(): void {
    // Aufruf bei jeder Parameter-Änderung (threadId)
    this.route.params.subscribe((params: any) => {
      const open =  history.state.open;
      this.action = history.state.action;
      this.sendTo = history.state.sendTo;
      if (this.deviceInfo.screenSizes['screen-very-small'] && open===true) {
        this.isOpen = true;
      }

      if (this.router.url.split('?')[0]=='/messages/new') {
        this.participants = []
        this.action = 'new';
        this.active = true;
        if (history.state.sendTo) {
          let params = new HttpParams();
          params = params.append('my', this.credential.profileId);
          this.profileService.getProfile(history.state.sendTo, false, params).subscribe(resp => {
            this.action = 'new1';
            this.participants.push(resp.data.profile);
          });
        }
      }
      else if (params.threadId) {
        this.action = 'select';
        this.threadId = params.threadId;
        this.getAllMessagesOfThread(this.threadId);
      }
    });
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.textMsgRef.nativeElement, 'paste', (event) => {
      event.preventDefault();
      const div = document.createElement('div');
      div.innerHTML = event.clipboardData.getData('text/plain');
      let pastedText = div.textContent || div.innerText || "";
      if (window.getSelection()) {
        let selection = window.getSelection();
        let range = selection!.getRangeAt(0);
        let span = document.createElement('span');
        span.classList.add('tmp');
        span.innerHTML = pastedText;
        range.deleteContents();
        range.insertNode(span);
        //cursor at the last with this
        range.collapse(false);
        selection!.removeAllRanges();
        selection!.addRange(range);
      }
      let regex = /<\/?span[^>]*>/g
      this.textMsgRef.nativeElement.innerHTML = this.textMsgRef.nativeElement.innerHTML.replace(regex, "");
    });
  }

  ngAfterViewChecked(): void {
    if (this.scrollToBottom) {
      this.messageListContainer.nativeElement.scrollTop = this.messageListContainer.nativeElement.scrollHeight;
      this.scrollToBottom = false;
    }
    /* if (this.textMsgRef) {
      this.textMsgRef.nativeElement.focus();
    } */
  }

  getAllMessagesOfThread(threadId: string): void {
    this.isLoaded = false;
    this.messages = null;
    this.messageService.getAllMessagesOfThread(threadId, new HttpParams()
      .set('my', this.credential.profileId)).subscribe(
      resp => {

        // Thread header
        if (resp.data.participants.length <= 2) { // remove current user if no group thread
          this.participants = resp.data.participants.filter((participant: any) => (
            !participant.id || (participant.id && participant.id !== this.credential.profileId)));
        } else {
          this.participants = resp.data.participants;
        }

        // Messages
        let messages = resp.data.messages;
        for (const message of messages) {
          if (message.textMsg) {
            message.textMsg = message.textMsg!.replace(/\n|\r\n|\r/g, '<br/>');
          }
          if (message.audioMsg) {
            message.audioMsg = environment.cdnUrl.concat(message.audioMsg);
          }
        }
        this.messages = messages;

        this.form.controls.textMsg.reset();
        this.form.controls.audioMsg.reset();
        this.checkIsActive();
        this.scrollToBottom = true;
        this.messageService.setCurrentThreadId(threadId);
        this.isLoaded = true;
      },
      error => {
        console.error(error);
        // this.logger.log(error);
        this.isLoaded = true;
      }
    );
  }

  getAllMessagesOfParticipants(participantIds: any): void {
    this.isLoaded = false;
    let params = new HttpParams();
    params = params.append('my', this.credential.profileId);
    params = params.append('ids', participantIds.join());
    this.messageService.getAllMessagesOfParticipants(params).subscribe(resp => {

      // Messages
      let messages = resp.data.messages;
      for (const message of messages) {
        if (message.textMsg) {
          message.textMsg = message.textMsg!.replace(/\n|\r\n|\r/g, '<br/>');
        }
        if (message.audioMsg) {
          message.audioMsg = environment.cdnUrl.concat(message.audioMsg);
        }
      }
      this.messages = messages;

      this.form.controls.textMsg.reset();
      this.form.controls.audioMsg.reset();
      this.checkIsActive();
      this.scrollToBottom = true;
    });
  }

  async sendMessage(audioMsg ? : any): Promise < void > {
    this.files = [];
    if (this.form.valid) {
      this.isSubmitted = true;
      this.isMediaRecorderVisible = false;
      if (audioMsg) {
        if (!audioMsg.audioBlob) return;
        const _blobToBase64 = await this.blobToBase64(audioMsg.audioBlob)
        this.form.patchValue({
          audioMsg: _blobToBase64
        });
      }
      let obj = this.formToObject();
      let message: Message = {};
      if (this.attachments && this.attachments.length !== 0) {
        this.messageService.saveMessage(obj).pipe(
          map(resp => {
            message.id = resp.data.id;
            message.threadId = resp.data.threadId;
            return message;
          }),
          mergeMap(_message =>
            from(this.attachments).pipe(
              mergeMap((attachment:any) =>
                this.uploadService.upload(this.getFormData(_message, attachment.selectedFile))
              )
            )
          )
        ).subscribe(resp => {
          this.files.push(resp);
          if (this.attachments.length == this.files.length) {
            this.attachments = [];
            this.continue(message);
          }
        })
      } else {
        this.messageService.saveMessage(obj).subscribe((resp) => {
          message.id = resp.data.id;
          message.threadId = resp.data.threadId;
          this.continue(message);
        });
      }
    }
  }

  private getFormData(message: any, file: any): FormData {
    let formData: any = {};
    formData.targetId = message.id;
    formData.targetType = 'message';
    formData.originalName = file.name;
    formData.fileType = file.type;
    formData.fileSize = file.size;
    formData.file = file.url;
    return formData;
  }

  private continue(message: Message): void {
    if (this.router.url.split('?')[0] == '/messages/new') {
      this.router.navigate(['/messages/' + message.threadId]);
    } else {
      if (this.form.controls.textMsg.value) {
        message.textMsg = this.form.controls.textMsg.value.replace(/\n|\r\n|\r/g, '<br/>');
      }
      message.audioMsg = this.form.controls.audioMsg.value;
      const sender: any = {
        id: this.credential.profileId,
        firstName: this.credential.firstName,
        lastName: this.credential.lastName,
        slug: this.credential.slug,
        slogan: this.credential.slogan,
        photoImage: this.credential.photoImage
      }
      message.sender = sender;
      // message.recipients = resp.data.recipients;
      message.recipients = this.participants;

      message.sendedAt = DateTime.local();
      if (this.messages == undefined) {
        this.messages = [];
      }
      this.messages.push(message);
      this.newThreadItemEvent.emit(message);
      // this.participants = message.recipients;
      this.form.reset();
      this.action = "";
      this.scrollToBottom = true;
      this.isSubmitted = false;
    }
  }

  setParticipants(profile: Profile[]): void {
    this.participants = [];
    const participantIds = [];
    for (let i = 0; i < profile.length; i++) {
      const participant: any = {
        id: profile[i].id,
        firstName: profile[i].firstName,
        lastName: profile[i].lastName,
        slug: profile[i].slug,
        slogan: profile[i].slogan,
        photoImage: profile[i].photoImage
      }
      this.participants.push(participant);
      participantIds.push(profile[i].id);
    }
    if (participantIds.length > 0) {
      this.getAllMessagesOfParticipants(participantIds);
    }
    this.checkIsActive();
  }

  checkIsActive() {
    if (this.action != 'new' && this.participants.filter((participant:any) => participant.id).length===0) {
      this.active = false;
    }
    else {
      this.active = true;
    }
  }

  formToObject(): any {
    Object.keys(this.form.controls).map((key) => {
      const control = this.form.get(key);
      if (control.value !== undefined && control.value != null && control.value.length == 0) {
        control.setValue(null);
      }
    });
    const obj:any = {};
    obj.sender = this.credential.profileId;
    let _recipients = this.participants.filter((participant:any) => participant.id !== obj.sender);
    obj.recipients = _recipients.map((elem: any) => {
       return {
         recipientId: elem.id // only id in request
       }
    })
    obj.threadId = this.threadId;
    obj.textMsg = this.form.value.textMsg;
    obj.audioMsg = this.form.value.audioMsg;
    obj.sendedAt = new Date();
    return obj;
  }

  firstAndLastName(profile: Profile): string {
    return profile.firstName!.concat(" ").concat(profile.lastName!);
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  addEmoji(event: any): void {
    const textMsg = this.textMsgRef.nativeElement.innerHTML;
    const textMsgPlusEmoji = `${textMsg}${event.emoji.native}`;
    this.form.get("textMsg").setValue(textMsgPlusEmoji);

    /* this.textMsgRef.nativeElement.focus();
    const selectionStart = this.textMsgRef.nativeElement.selectionStart;
    const currentValue = this.textMsgRef.nativeElement.innerHTML;
    const newValue = currentValue.substring(0, selectionStart) + event.emoji.native + currentValue.substring(selectionStart);
    // this.messageFormControl.setValue(newValue);
     this.form.get("textMsg").setValue(newValue);
    // the following 2 lines set the caret position behind the emoji
    this.textMsgRef.nativeElement.selectionStart = selectionStart + event.emoji.native.length;
    this.textMsgRef.nativeElement.selectionEnd = selectionStart + event.emoji.native.length;*/

    this.showEmojiPicker = false;
  }

  onFocus(): void {
    this.showEmojiPicker = false;
  }

  onBlur(): void {
    this.showEmojiPicker = false;
  }

  hasCharsX(event: any): void {
    this.hasChars = this.form.value.textMsg.length > 0 ? true : false;
  }

  activateAudioReorder() {
    this.isMediaRecorderVisible = true;
  }

  report(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '800px';
    dialogConfig.data = {
      threadId: this.threadId,
      objectType: 'MessageThread',
    };
    const dialogRef = this.dialog.open(ReportComponent,
      dialogConfig);
  }

  archive(): void {
    const statusObj = {
      threadId: this.threadId,
      recipientId: this.credential.profileId
    }
    this.messageService.setArchiveStatus(statusObj).subscribe((resp) => {
      this.router.navigate(['/messages']);
    });
  }

  restore(): void {
    const statusObj = {
      threadId: this.threadId,
      recipientId: this.credential.profileId
    }
    this.messageService.setArchiveStatus(statusObj).subscribe((resp) => {
      this.router.navigate(['/messages']);
    });
  }

  delete(): void {
    const threadObj = {
      threadId: this.threadId,
      recipientId: this.credential.profileId
    }
    this.messageService.deleteThread(threadObj).subscribe((resp) => {
      this.router.navigate(['/messages']);
    });
  }

  sender(message: any) {
    let participants = message.participants.filter((participant:any) => (
      (!participant.senderId && !participant.recipientId) || participant.senderId));
    return participants[0].sender;
  }

  sendedAtDateAsString(date: any) {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
  }

  onFileSelected(event: any): void {
    let selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles[0]) {
      let reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        this.attachments.push({
          selectedFile: selectedFiles[0],
          dataUrl: e.target.result,
          name: selectedFiles[0].name,
          type: selectedFiles[0].type,
          size: selectedFiles[0].size
        });
      }
      reader.readAsDataURL(selectedFiles[0]);
    };
  }

  cdnUrl(url: string): string {
    return environment.cdnUrl.concat(url);
  }

  // accepted: image/*,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt"
  getIconForMimeType(mimeType: string):IconName {
    let icon = null;
    switch (mimeType) {
      // pdf
      case "application/pdf":
        icon = "file-pdf" as IconName;
        break;

      // csv
      case "text/csv":
        icon = "file-csv" as IconName;
        break;

      // csv, rtf
      case "text/plain":
      case "application/rtf":
        icon = "file-lines" as IconName;
        break;

      // .doc, .dot
      case "application/msword":
      // .docx
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      // .odt
      case "application/vnd.oasis.opendocument.text":
        icon = "file-word" as IconName;
        break;

      // .xls
      case "application/vnd.ms-excel":
      // .xlsx
      case "application / vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        icon = "file-excel" as IconName;
        break;

      // .ppt
      case "application/vnd.ms-powerpoint":
      // .pptx
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        icon = "file-powerpoint" as IconName;
        break;

      default:
        icon = "file" as IconName;
    }
    return icon;
  }

  saveFileAs(event: Event, file: any): void {
    event.preventDefault();
    this.http.get(`${environment.cdnUrl}${file.url}`, {
        responseType: "blob"
      }).toPromise()
      .then((blob: any) => {
        this.fileSaverService.save(blob, file.originalName);
      })
      .catch(err => console.error("download error = ", err))
  }

  removeAttachment(id: number) {
    this.attachments = this.attachments.filter((attachment:any) => attachment.id !== id);
  }
}

