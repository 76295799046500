<div mat-dialog-title class="dialog-title">
  <h2>{{'post.settings' | translate}}</h2>
  <button mat-icon-button mat-dialog-close>
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
<div class="mb-20" class="settings-panel" *ngIf="groups && organizations">
  <form [formGroup]="form" #ngForm="ngForm" novalidate>
    <div *ngIf="viewable==='settings'">
      <div>
        <mat-label>{{'post.visibility.for' | translate}}</mat-label>
        <mat-radio-group class="rb-settings" formControlName="visibility" labelPosition="before" (change)="change($event)">
          <mat-radio-button value="a">
            <fa-icon icon="globe"></fa-icon>
            <span class="radio-button-label">{{'post.visibility.all' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button value="c">
            <fa-icon icon="people-arrows"></fa-icon>
            <span class="radio-button-label">{{'post.visibility.contacts' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button value="g" *ngIf="groups.length > 0">
            <fa-icon icon="users"></fa-icon>
            <span class="radio-button-label">{{'post.visibility.groups' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button value="o" *ngIf="organizations.length > 0">
            <fa-icon icon="industry"></fa-icon>
            <span class="radio-button-label">{{'post.visibility.organizations' | translate}}</span>
          </mat-radio-button>
          <mat-radio-button value="m">
            <fa-icon icon="user"></fa-icon>
            <span class="radio-button-label">{{'post.visibility.me' | translate}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div mat-dialog-actions align="end">
        <button (click)="back()" mat-flat-button>
          {{'entity.action.cancel' | translate}}
        </button>
        <button (click)="save()" mat-flat-button color="primary">
          {{'entity.action.save' | translate}}
        </button>
      </div>
    </div>
    <div class="mb-20 ext" *ngIf="viewable==='select-group'">
      <div class="group-list" [style.height.px]="this.settings.groupId ? 200 : 240">
        <mat-label class="radio-button-label">{{'post.select.group' | translate}}</mat-label>
        <mat-radio-group class="rb-settings" formControlName="groupId" labelPosition="before" (change)="selectGroup($event)">
          <mat-radio-button *ngFor="let group of groups" [value]="group.id">
            <span class="radio-button-label">{{group.name}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="forums && forums.length > 0">
        <mat-label class="select-label">{{'post.select.forum' | translate}}</mat-label>
        <mat-select placeholder="{{'post.select.forum.pl' | translate}}" formControlName="forumId" [disableOptionCentering]="true" (selectionChange)="selectForum($event)">
          <mat-option *ngFor="let forum of forums" [value]="forum.id">
            <span>{{forum.name}}</span>
          </mat-option>
        </mat-select>
      </div>
      <div mat-dialog-actions align="end">
        <button (click)="backToSettings()" mat-flat-button>
          {{'entity.action.back' | translate}}
        </button>
        <button (click)="save()" mat-flat-button color="primary"
                [disabled]="!form.value.groupId || !form.value.forumId">
          {{'entity.action.select' | translate}}
        </button>
      </div>
    </div>
    <div class="mb-20 ext" *ngIf="viewable==='select-organization'">
      <div class="organization-list">
        <mat-label class="radio-button-label">{{'post.select.organization' | translate}}</mat-label>
        <mat-radio-group class="rb-settings" formControlName="organizationId" labelPosition="before" (change)="selectOrganization($event)">
          <mat-radio-button *ngFor="let organization of organizations" [value]="organization.id">
            <span class="radio-button-label">{{organization.name}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div mat-dialog-actions align="end">
        <button (click)="backToSettings()" mat-flat-button>
          {{'entity.action.back' | translate}}
        </button>
        <button (click)="save()" mat-flat-button color="primary"
                [disabled]="!form.value.organizationId">
          {{'entity.action.select' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
