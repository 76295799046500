import { Component, EventEmitter, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@shared/material.module';
import { FileUploadService } from '@core/services/file-upload.service';
import { environment } from '@environments/environment';

@Component({
  selector: '[xa-document]',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  standalone: true,
  imports: [PdfJsViewerModule, TranslateModule, FontAwesomeModule, MaterialModule, RouterModule, CommonModule]
})
export class DocumentComponent implements AfterViewInit {
  @ViewChild('dummyClick') dummyClickRef: ElementRef;
  @Output() selectedDocument = new EventEmitter();
  isSubmitted: boolean = false;
  selectedFiles: FileList;
  files: IFile[] = [];
  state: string = 'initial';

  constructor(
    private uploadService: FileUploadService
  ) {}

  ngAfterViewInit(): void {
    this.dummyClickRef.nativeElement.click()
  }

  onSelectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    this.files = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      this.state = 'loading';
      let reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        this.files.push({
          url: e.target.result,
          urlAB: this.base64ToArrayBuffer(e.target.result),
          name: this.selectedFiles[0].name,
          type: this.selectedFiles[0].type,
          size: this.selectedFiles[0].size
        });
      }
      reader.onerror = (e: any) => {
        this.state = 'error';
      }
      reader.onloadend = (e: any) => {
        this.state = 'initial';
      }
      reader.readAsDataURL(this.selectedFiles[0]);
    }
  }

  done(): void {
    this.selectedDocument.emit(this.files)
  }

  base64ToArrayBuffer(base64: any) {
    let byteCharacters = atob(base64.split('base64,')[1]);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    return byteArray;
  }
}

export interface IFile {
  url: any;
  urlAB: any;
  name: string;
  type: string;
  size: any;
}
