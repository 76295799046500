<div [ngClass]="isEditing ? 'comment-item-nm' : 'comment-item margin-top'">

    <div class="flex-row">
      <div class="avatar" *ngIf="!isEditing">
        <a routerLink="/profile/{{credential.slug}}">
          <img [src]="credential.photoImage | image: 'avatar':'s1'" title="{{credential.firstName}} {{credential.lastName}}" alt="{{credential.firstName}} {{credential.lastName}}" />
        </a>
      </div>
      <div class="comment-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <div formControlName="text" #text attr.placeholder="{{'comment.placeholder' | translate}}" contenteditable="true" class="contenteditable" ></div>
        <div class="right-align" *ngIf="this.form.value.text && this.form.value.text.length >= 10" >
          <div class="is-submitted-wheel" *ngIf="isSubmitted"></div>
          <button type="button" class="btn" (click)="handleCancel.emit()" *ngIf="hasCancelButton">
            {{'entity.action.cancel' | translate}}
          </button>
          <button type="submit" style="margin: 1em" mat-flat-button color="primary" [disabled]="!form.valid || isSubmitted">
            {{'comment.post' | translate}}
          </button>
        </div>
        </form>
      </div>
    </div>

</div>
