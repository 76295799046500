import { Component, OnInit, HostBinding, ViewChild, ElementRef, Renderer2, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { AccountService } from '@modules/account/services/account.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ListLikesComponent } from '@modules/like/components/list-likes.component';
import { LikeService } from '@modules/like/services/like.service';
import { Credential } from '@modules/account/models/credential.model';

@Component({
  selector: '[xa-like-icon]',
  templateUrl: './like-icon.component.html',
  styleUrls: ['./like-icon.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, MaterialModule, TranslateModule, CommonModule]
})
export class LikeIconComponent implements OnInit {
  @HostBinding('className') componentClass: string;
  @ViewChild("like") like: ElementRef;
  @Input() likedId: number;
  @Input() likedType: string;
  @Input() isLiked: boolean;
  @Output() isLikedChange = new EventEmitter()
  credential: Credential;
  noOfLikes: number = 100;
  likeClass = ['like-button'];

  constructor(
    private accountService: AccountService,
    private likeService: LikeService,
    private renderer: Renderer2,
    private dialog: MatDialog,
    public deviceInfo: DeviceInfoService
  ) {
    this.componentClass = 'xa-like-icon';
  }

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.like.nativeElement, 'click', (event) => {
      switch (this.isLiked) {
        case false:
          this.isLiked = true;
          this.noOfLikes = this.noOfLikes + 1;
          break;
        case true:
          this.isLiked = false;
          this.noOfLikes = this.noOfLikes - 1;
          break;
      }
      const like: any = {
        isLiked: this.isLiked,
        likedId: this.likedId,
        likedType: this.likedType,
        likedAt: new Date(),
        likedById: this.credential.profileId
      }
      this.likeService.saveLike(like).subscribe(resp => {
        this.isLikedChange.emit(this.isLiked);
      });
    })
  }

  onClickLikes(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "800px";
    dialogConfig.data = {};

    const dialogRef = this.dialog.open(ListLikesComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(edit => {
      /* if (edit) {

      }*/
    });
  }
}
