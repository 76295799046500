import { Directive, OnInit, ElementRef, Renderer2, Input } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Directive({
  selector: '[xaActiveMenu]',
})
export class ActiveMenuDirective implements OnInit {
  @Input() xaActiveMenu?: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateActiveFlag(event.lang);
    });
    this.updateActiveFlag(this.translate.currentLang);
  }

  updateActiveFlag(selectedLanguage: string): void {
    if (this.xaActiveMenu==selectedLanguage) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
  }
}
