<div mat-dialog-title class="dialog-title">
  <h2>{{title}}</h2>
  <button mat-icon-button mat-dialog-close>
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>

<div mat-dialog-content>
  <p [innerHTML]="message"></p>
</div>

<div mat-dialog-actions  align="end">
  <button mat-flat-button mat-dialog-close>
    {{closeBtn}}
  </button>
  <button mat-flat-button color="primary" (click)="onConfirm()" *ngIf="confirmBtn">
    {{confirmBtn}}
  </button>
</div>
