import { Component, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LikeService } from '@modules/like/services/like.service';

@Component({
  selector: 'xa-list-likes',
  templateUrl: './list-likes.component.html',
  styleUrls: ['./list-likes.component.scss']
})
export class ListLikesComponent {
  likes: any;
  likedType: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {likedId: number, likedType: string},
    private dialog: MatDialog,
    private likeService: LikeService,
  ) {
    this.likedType = data.likedType;
    let params = new HttpParams();
    params = params.append('likedId', data.likedId);
    params = params.append('likedType', data.likedType);
    this.likeService.getLikes(params).subscribe(resp => {
      this.likes = resp.data.likes;
    });
  }
}
