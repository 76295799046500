import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderService } from '@core/services/loader.service';

/*
*  Spinner component (https://danielk.tech/home/angular-how-to-add-a-loading-spinner)
*  <div xa-spinner></div>
*/

@Component({
  selector: '[xa-spinner]',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SpinnerComponent {
  constructor(
    public loader: LoaderService
  ) { }
}
